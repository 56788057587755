<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ organization.name }} - {{ $t("COMMON.SUBSCRIPTIONS") }}
    </h3>
    <subscription-list-table :filterOrganization="organization.id" />
  </div>
</template>

<script>
import SubscriptionListTable from "@/views/Pages/ResellerModule/SubscriptionManagement/partials/SubscriptionListTable.vue";

export default {
  name: "organization-view-subscriptions",

  components: { SubscriptionListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
