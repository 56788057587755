<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ organization.name }} - {{ $t("ORGANIZATIONS.AUTHORIZATIONS") }}
    </h3>
    <permissions-table :permissionsData="organization.activePermissions" />
  </div>
</template>

<script>
import PermissionsTable from "@/components/PermissionsTable.vue";

export default {
  name: "organization-view-authorizations",

  components: { PermissionsTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
